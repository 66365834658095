// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$wedding-invitation-primary: mat.define-palette(mat.$indigo-palette);
$wedding-invitation-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$wedding-invitation-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$wedding-invitation-theme: mat.define-light-theme(
    (
        color: (
            primary: $wedding-invitation-primary,
            accent: $wedding-invitation-accent,
            warn: $wedding-invitation-warn,
        ),
        typography: mat.define-typography-config(),
        density: 0,
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($wedding-invitation-theme);

@font-face {
    font-family: kepler;
    src: url('assets/fonts/kepler-regular.ttf');
}

@font-face {
    font-family: pinyon-script;
    src: url('assets/fonts/pinyon-script-regular.ttf');
}

@font-face {
    font-family: kunstler-script;
    src: url('assets/fonts/kunstler-script-regular.ttf');
}

@font-face {
    font-family: apothicaire-light;
    src: url('assets/fonts/apothicaire-light.otf');
}

/* You can add global styles to this file, and also import other style files */
html,
body {
    height: 100%;
}
body {
    margin: 0 !important;
    font-family: kepler;
    color: #221f20;
}

/* Animation */
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    25% {
        opacity: 0.25;
    }
    50% {
        opacity: 0.5;
    }
    75% {
        opacity: 0.75;
    }
    100% {
        opacity: 1;
    }
}

@keyframes blur-out {
    0% {
        filter: blur(50px);
    }
    25% {
        filter: blur(30px);
    }
    50% {
        filter: blur(15px);
    }
    75% {
        filter: blur(5px);
    }
    100% {
        filter: blur(0px);
    }
}

@keyframes grow {
    0% {
        outline-width: 0px;
    }
    25% {
        outline-width: 0.75px;
    }
    50% {
        outline-width: 1.5px;
    }
    75% {
        outline-width: 2.25px;
    }
    100% {
        outline-width: 3px;
    }
}

@keyframes text-emphasis {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}
